<template>
  <div class="SmartTask">
    <div class="SmartTask-top">
      <!-- <div class="SmartTask-top-left"> -->
      <a-select
        placeholder="任务状态"
        @change="search"
        v-model:value="initParams.state"
        allowClear
      >
        <a-select-option value="1">未开始</a-select-option>
        <a-select-option value="2">进行中</a-select-option>
        <a-select-option value="3">已完成</a-select-option>
        <a-select-option value="4">暂停中</a-select-option>
      </a-select>
      <!-- <a-select placeholder="任务状态">
        <a-select-option value="1">开启</a-select-option>
        <a-select-option value="0">关闭</a-select-option>
      </a-select> -->
      <a-select
        placeholder="话术模板"
        v-model:value="initParams.talk_temp_id"
        allowClear
        @change="search"
      >
        <!-- <a-select-option
            v-for="item in topList.talkTempList"
            :key="item.talk_temp_id"
            :value="item.talk_temp_id"
            >{{ item.talk_temp_name }}</a-select-option
          > -->
        <a-select-option value="1">SCRM招商</a-select-option>
        <a-select-option value="2">pos机</a-select-option>
      </a-select>
      <a-select
        placeholder="线路"
        v-model:value="initParams.lines_id"
        allowClear
        @change="search"
      >
        <!-- <a-select-option
            v-for="item in topList.relayList"
            :key="item.relay_id"
            :value="item.relay_id"
            >{{ item.relay_name }}</a-select-option
          > -->
        <a-select-option value="1">scrms</a-select-option>
        <a-select-option value="2">djsds</a-select-option>
      </a-select>
      <a-range-picker
        v-model:value="initParams.create_time"
        valueFormat="YYYY-MM-DD"
        style="width: 200px; margin-right: 15px"
        @change="search"
      />
      <!-- <a-input-search placeholder="搜索" allowClear /> -->
      <!-- <a-button type="primary" style="margin-right: 15px" @click="handleAdd">
        <template #icon><PlusOutlined /></template>
        新建任务
      </a-button>
      <a-button>暂停所有任务</a-button> -->
      <a-dropdown class="menu-dropdown">
        <template #overlay>
          <a-menu @click="AddTask">
            <a-menu-item key="1"> <PlusOutlined />新建任务 </a-menu-item>
            <a-menu-item key="2"> <EditOutlined /> 编辑任务 </a-menu-item>
            <a-menu-item key="3" style="color: red"> 暂停所有任务 </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">
          菜单
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-modal
        v-model:visible="visible2"
        title="暂停所有任务"
        :maskClosable="false"
        :width="400"
        okText="确定"
        cancelText="取消"
        @ok="okTask"
      >
        <p>确认暂停所有任务？</p>
      </a-modal>
      <!-- </div> -->
      <!-- <div class="SmartTask-top-right"> -->

      <!-- </div> -->
    </div>
    <div class="SmartTask-content">
      <div class="SmartTask-content-left" v-if="leftList.length !== 0">
        <div class="content-left-top">
          <div
            class="left-top-item"
            @click="handleClick(index)"
            v-for="(item, index) in leftList"
            :key="index"
            :class="{ active: current === index }"
          >
            <!-- <img
              src="~@/assets/off.png"
              class="delete"
              v-if="index === current"
            /> -->
            <a-descriptions :title="item.job_name">
              <template #extra>
                <span :class="{ spanAct: item.state == 4, moren: true }">{{
                  handleStatu(item.state)
                }}</span>
              </template>
              <a-descriptions-item label="创建人">
                {{ item.username }}
              </a-descriptions-item>
              <a-descriptions-item label="呼出量"
                >{{ item.finished_number_quantity }}/{{
                  item.number_quantity
                }}</a-descriptions-item
              >
              <a-descriptions-item label="话术模板">{{
                item.talk_temp_name
              }}</a-descriptions-item>
            </a-descriptions>
            <a-progress
              :stroke-color="{
                '0%': '#108ee9',
                '100%': '#87d068',
              }"
              :percent="
                Math.ceil(
                  (item.finished_number_quantity / item.number_quantity) * 100
                )
              "
            />
          </div>
        </div>
        <div
          class="content-left-btn"
          style="text-align: right; margin-right: 15px"
        >
          <a-pagination
            @change="handlePage"
            :pageSize="6"
            :total="total"
            :current="initParams.page"
          />
        </div>
      </div>
      <div class="SmartTask-content-left" v-else>
        <a-empty style="margin-top: 20px" />
      </div>

      <!-- <div
        style="
          background-color: #fff;
          flex: 1;
          height: calc(100% - 0px);
          overflow: auto;
        "
      > -->
      <TaskContent :uuid="uuid" @taskChange="iKnow" :state="state" />
      <!-- </div> -->
    </div>
    <a-modal
      v-model:visible="visible"
      title="新增任务"
      centered
      :maskClosable="false"
      class="modalAdd"
      :width="800"
      okText="确定"
      cancelText="取消"
      @ok="handleTaskAdd"
      @cancel="formStateRest"
    >
      <a-form class="Form">
        <a-form-item label="任务名称">
          <a-input v-model:value="formState.job_name" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="话术模板">
          <a-select v-model:value="formState.speech_id" placeholder="请选择">
            <a-select-option
              v-for="item in speech_list"
              :key="item.talk_temp_id"
              :value="item.talk_temp_id"
              >{{ item.talk_temp_name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="工作日期">
          <a-select v-model:value="formState.work_week" mode="multiple">
            <a-select-option value="1">周一</a-select-option>
            <a-select-option value="2">周二</a-select-option>
            <a-select-option value="3">周三</a-select-option>
            <a-select-option value="4">周四</a-select-option>
            <a-select-option value="5">周五</a-select-option>
            <a-select-option value="6">周六</a-select-option>
            <a-select-option value="0">周日</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="机器人数量">
          <a-input v-model:value="formState.limit" placeholder="请输入" />
        </a-form-item>

        <a-form-item label="启停时间">
          <a-range-picker
            :locale="locale"
            valueFormat="YYYY-MM-DD"
            v-model:value="formState.time"
          />
        </a-form-item>
        <a-form-item
          label="工作时间"
          class="add-time"
          v-for="(item, index) in formState.time_range"
          :key="item.id"
        >
          <div style="display: flex; align-items: center; width: 266px">
            <a-time-picker
              :allowClear="false"
              valueFormat="HH:mm:ss"
              v-model:value="item.begin"
            />~
            <a-time-picker
              :allowClear="false"
              valueFormat="HH:mm:ss"
              v-model:value="item.end"
            />
          </div>
          <div v-if="index === 0" class="add-icon" @click="addDomain">
            <PlusOutlined />
          </div>
          <DeleteOutlined
            v-else
            class="dynamic-delete-button add-icon"
            @click="removeDomain(index)"
          />
        </a-form-item>

        <a-form-item label="线路组">
          <a-select
            mode="multiple"
            v-model:value="formState.line_group"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in line_groups"
              :key="item.lines_id"
              :value="item.lines_id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="客户跟进">
          <a-radio-group v-model:value="formState.crm.crm">
            <a-popover trigger="click" placement="bottomLeft">
              <template #content>
                <div class="radioGroup-content">
                  <a-checkbox-group v-model:value="formState.crm.label">
                    <a-checkbox
                      v-for="item in speech_label"
                      :key="item.lab_id"
                      :value="item.point"
                      >{{ item.point }}</a-checkbox
                    >
                  </a-checkbox-group>
                  <a-select
                    placeholder="推送方式"
                    v-model:value="formState.crm.turn"
                    style="margin: 5px 0"
                  >
                    <a-select-option value="turn">顺序推送</a-select-option>
                    <a-select-option value="all">全部推送</a-select-option>
                    <a-select-option value="rand">随机推送</a-select-option>
                  </a-select>
                  <a-select
                    placeholder="请选择"
                    mode="multiple"
                    v-model:value="formState.crm.clerk_id"
                  >
                    <a-select-option
                      v-for="item in clerk_list"
                      :key="item.user_id"
                      :value="item.user_id"
                      >{{ item.username }}</a-select-option
                    >
                  </a-select>
                </div>
              </template>
              <a-button @click="handlePrivate">
                <a-radio value="private">私海</a-radio></a-button
              >
            </a-popover>
            <a-popover trigger="click" placement="bottomLeft">
              <template #content>
                <div class="radioGroup-content">
                  <a-checkbox-group v-model:value="formState.crm.label2">
                    <a-checkbox
                      v-for="item in speech_label"
                      :key="item.lab_id"
                      :value="item.point"
                      >{{ item.point }}</a-checkbox
                    >
                  </a-checkbox-group>
                </div>
              </template>
              <a-button @click="handlePublic">
                <a-radio value="public">公海</a-radio></a-button
              >
            </a-popover>
          </a-radio-group>
        </a-form-item>

        <!-- <a-form-item label="任务状态">
          <a-switch v-model:checked="formState.enable" />
        </a-form-item> -->
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted } from "vue";
import TaskContent from "./TaskContent.vue";
// import { getLeft, getTop, getAddTask } from "../../service/smarttask";
import {
  getOptions,
  postTaskAdd,
  gettTaskList,
  getTaskEdit,
  postTaskEdit,
  postTaskStops,
} from "../../service/smarttask";
import { handleStatu } from "../../utils/function";
// import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
// import OSS from "ali-oss";
import { message } from "ant-design-vue";

export default {
  name: "SmartTask",
  components: {
    PlusOutlined,
    TaskContent,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
  },
  setup() {
    const state = reactive({
      current: 0,
      total: 0,
      leftList: [],
      uuid: undefined,
      state: undefined, //任务状态
      visible: false,
      speech_list: [],
      line_groups: [],
      clerk_list: [],
      speech_label: [],
      isEdit: false,
      visible2: false,
    });
    const formState = reactive({
      job_name: undefined,
      time: [], //开始结束日期
      work_week: ["1", "2", "3", "4", "5"],
      // enable: true,
      speech_id: undefined,
      limit: undefined,
      time_range: [
        {
          begin: "08:00:00",
          end: "20:00:00",
          id: 1,
        },
      ],
      // file: [],
      line_group: [],
      crm: {
        crm: "private",
        label: [],
        label2: [],
        turn: "turn",
        clerk_id: [],
      },
      // handle: "handle",
      // number: undefined,
    });
    const formStateRest = () => {
      formState.job_name = undefined;
      formState.time = []; //开始结束日期
      formState.work_week = ["1", "2", "3", "4", "5"];
      formState.speech_id = undefined;
      formState.limit = undefined;
      (formState.time_range = [
        {
          begin: "08:00:00",
          end: "20:00:00",
          id: 1,
        },
      ]),
        // file: [],
        (formState.line_group = []);
      formState.crm = {
        crm: "private",
        label: [],
        label2: [],
        turn: "turn",
        clerk_id: [],
      };
    };
    const initParams = reactive({
      page: 1,
      limit: 5,
      state: undefined,
      talk_temp_id: undefined,
      lines_id: undefined,
      // createtime: [],
      create_time: [],
      // access_token: sessionStorage.getItem("token"),
    });
    const getOptionsFun = async () => {
      const res = await getOptions({
        access_token: sessionStorage.getItem("token"),
      });
      // console.log(res);
      state.line_groups = res.data.data.line_groups;
      state.speech_list = res.data.data.speech_list;
      state.clerk_list = res.data.data.clerk_list;
      state.speech_label = res.data.data.speech_label;
    };
    // watch(
    //   () => state.current,
    //   (newval) => {
    //     state.uuid = state.leftList[newval].uuid;
    //   }
    // );
    onMounted(async () => {
      // const res2 = await getTop({
      //   access_token: sessionStorage.getItem("token"),
      // });
      // state.topList = res2.data.data;
      // console.log(res2);
      init();
    });
    const search = () => {
      // console.log(e);
      // console.log(initParams);
      initParams.page = 1;
      init();
    };
    let init = async () => {
      const params = {
        page: initParams.page,
        limit: initParams.limit,
        state: initParams.state,
        talk_temp_id: initParams.talk_temp_id,
        lines_id: initParams.lines_id,
        // createtime: [],
        create_time:
          initParams.create_time.length === 0
            ? undefined
            : JSON.stringify(initParams.create_time),
        access_token: sessionStorage.getItem("token"),
      };
      // initParams.create_time =
      //   Object.keys(initParams.createtime) === 0
      //     ? undefined
      //     : JSON.stringify(initParams.createtime);
      // console.log(initParams);
      const res = await gettTaskList({
        ...params,
      });
      // console.log(res);
      state.leftList = res.data.data.list;
      state.total = res.data.data.total;
      state.uuid = state.leftList[0]?.job_id;
      state.state = state.leftList[0]?.state;
    };
    // 暂停所有任务
    const okTask = async () => {
      const res = await postTaskStops({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.visible2 = false;
      if (res.status === 100000) {
        message.success(res.data.msg);
        return;
      } else {
        message.error(res.data.msg);
        return;
      }
    };
    const handleClick = (index) => {
      state.current = index;
      state.uuid = state.leftList[index].job_id;
      state.state = state.leftList[index].state;
    };
    const handlePage = (e) => {
      // console.log(e);
      initParams.page = e;
      init();
      state.current = 0;
      state.uuid = state.leftList[state.current].job_id;
    };
    const handleAdd = async () => {
      state.visible = true;
      getOptionsFun();
    };

    // const handleChangeWeeks = (e) => {
    //   console.log(e);
    // };

    const handleRemove = () => {
      formState.file = [];
    };

    const beforeUpload = (file) => {
      formState.file = [file];
      return false;
    };
    const removeDomain = (index) => {
      formState.time_range.splice(index, 1);
    };
    const addDomain = () => {
      formState.time_range.push({
        id: Date.now(),
        begin: "09:00:00",
        end: "20:00:00",
      });
    };
    const handleTaskAdd = async () => {
      console.log(formState);
      let timeTest = formState.time_range;
      let end = [];
      let begin = [];
      for (let i = 0; i < timeTest.length; i++) {
        for (let y = i + 1; y < timeTest.length; y++) {
          end = timeTest[i].end.split(":");
          begin = timeTest[y].begin.split(":");
          console.log(end, begin);
          if (
            Number(end[0]) < Number(begin[0]) ||
            Number(end[0]) === Number(begin[0])
          ) {
            // console.log("第一个结束时间小于第二个开始时间");
            if (
              Number(end[1]) < Number(begin[1]) ||
              Number(end[1]) === Number(begin[1])
            ) {
              if (
                Number(end[2]) < Number(begin[2]) ||
                Number(end[2]) === Number(begin[2])
              ) {
                console.log('不冲突');
              } else {
                message.warning("工作时间冲突");
                return;
              }
            } else {
              message.warning("工作时间冲突");
              return;
            }
          } else {
            message.warning("工作时间冲突");
            return;
          }
        }
      }
      const params = {
        job_name: formState.job_name,
        work_week: formState.work_week.join(","),
        // enable: formState.enable,
        speech_id: formState.speech_id,
        limit: formState.limit,
        time_range: formState.time_range,
        line_group: formState.line_group.join(","),
        crm: {
          crm: formState.crm.crm,
        },
        time: {
          begin: formState.time[0],
          end: formState.time[1],
        },
      };
      if (formState.crm.crm === "private") {
        params.crm.turn = formState.crm.turn;
        params.crm.label = formState.crm.label.join(",");
        params.crm.clerk_id = formState.crm.clerk_id.join(",");
      } else {
        params.crm.label = formState.crm.label2.join(",");
      }
      // console.log(params);
      params.time_range = JSON.stringify(params.time_range);
      params.crm = JSON.stringify(params.crm);
      params.time = JSON.stringify(params.time);
      if (state.isEdit) {
        params.job_id = state.leftList[state.current].job_id;
        const res = await postTaskEdit({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        // console.log(res);
        if (res.data.status === 100000) {
          message.success("操作成功");
          state.visible = false;
          state.isEdit = false;
          state.current = 0;
          initParams.page = 1;
          init();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await postTaskAdd({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        // console.log(res);
        if (res.data.status === 100000) {
          message.success("操作成功");
          state.visible = false;
          state.current = 0;
          initParams.page = 1;
          init();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const iKnow = (date) => {
      // console.log(date);
      switch (date) {
        case "中止任务" || "任务开启":
          updateTask();
          break;
        case "导入成功":
          updateTask();
          break;
        default:
          initParams.page = 1;
          state.current = 0;
          init();
          break;
      }
    };
    const updateTask = async () => {
      const params = {
        page: initParams.page,
        limit: initParams.limit,
        state: initParams.state,
        talk_temp_id: initParams.talk_temp_id,
        lines_id: initParams.lines_id,
        // createtime: [],
        create_time:
          initParams.create_time.length === 0
            ? undefined
            : JSON.stringify(initParams.create_time),
        access_token: sessionStorage.getItem("token"),
      };
      const res = await gettTaskList({
        ...params,
      });
      // console.log(res);
      state.leftList = res.data.data.list;
      state.state = res.data.data.list[state.current].state;
      // state.total = res.data.data.total;
      // state.uuid = state.leftList[state.current].job_id;
    };
    const handlePublic = () => {
      formState.crm.crm = "public";
    };
    const handlePrivate = () => {
      formState.crm.crm = "private";
    };
    const AddTask = (e) => {
      // console.log(e);
      switch (e.key) {
        case "1":
          handleAdd();
          break;
        case "2":
          // console.log(state.leftList[state.current].state);
          if (state.leftList[state.current].state === 2) {
            message.error("请先暂停任务");
            return;
          }
          state.visible = true;
          state.isEdit = true;
          getTaskEditFun();
          break;
        case "3":
          state.visible2 = true;
          break;
      }
    };
    //任务编辑获取数据
    const getTaskEditFun = async () => {
      const res = await getTaskEdit({
        access_token: sessionStorage.getItem("token"),
        job_id: state.leftList[state.current].job_id,
      });
      console.log(res);
      const {
        clerk_list,
        job,
        line_groups,
        speech_label,
        speech_list,
        time_range,
      } = res.data.data;
      state.clerk_list = clerk_list;
      state.line_groups = line_groups;
      state.speech_label = speech_label;
      state.speech_list = speech_list;
      formState.job_name = job.job_name;
      formState.work_week = job.work_week.split(",");
      formState.time = [formatDate(job.start_time), formatDate(job.stop_time)];
      formState.speech_id = job.speech_id;
      formState.limit = job.limit;
      formState.line_group = job.line_group
        .split(",")
        .map((item) => Number(item));
      formState.time_range = time_range.map((item) => {
        return {
          begin: item.begin_datetime,
          end: item.end_datetime,
          id: item.id,
        };
      });
      formState.crm = {
        crm: job.crm,
        label2: job.crm === "public" ? job.label.split(",") : [],
        label1: job.crm === "private" ? job.label.split(",") : [],
        turn: ["turn", "all", "rand"].includes(job.enable)
          ? job.enable
          : "turn",
        clerk_id: job.clerk_id ? job.clerk_id.split(",") : [],
      };
      // console.log(formState);
    };
    function formatDate(ts) {
      var now = new Date(parseInt(ts) * 1000);
      var year = now.getFullYear();
      var month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;
      var date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      return year + "-" + month + "-" + date;
    }
    return {
      handleClick,
      ...toRefs(state),
      handlePage,
      handleStatu,
      handleAdd,
      // handleChangeWeeks,
      formState,
      locale,
      handleRemove,
      beforeUpload,
      removeDomain,
      addDomain,
      handleTaskAdd,
      initParams,
      iKnow,
      handlePublic,
      handlePrivate,
      AddTask,
      search,
      formStateRest,
      okTask,
    };
  },
};
</script>
<style>
.Form .ant-form-item .ant-form-item-label {
  min-width: 90px;
}
.SmartTask .content-left-top .ant-descriptions-row {
  display: flex;
  flex-direction: column;
}
.SmartTask .ant-descriptions-header {
  margin-bottom: 8px !important;
}
.SmartTask .ant-descriptions-item {
  padding-bottom: 4px;
}
.SmartTask .ant-descriptions-title {
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}
</style>
<style scoped>
.SmartTask {
  height: 100%;
  overflow: hidden;
}
.SmartTask-top {
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}
.SmartTask-content {
  height: calc(100% - 63px);
  display: flex;
}

.SmartTask-content-left {
  background-color: #fff;
  border-right: 1px solid #f5f5f5;
  border-top: 1px solid #f5f5f5;
  height: 100%;
  padding-bottom: 4px;
  width: 302px;
  min-width: 302px;
}
/* @media screen and (max-width: 1500px) {
  .SmartTask-content-left {
    width: 272px;
    min-width: 272px;
  }
}
@media screen and (max-width: 1395px) {
  .SmartTask-content {
    height: calc(100% - 94px);
  }
} */
.SmartTask-top .ant-select,
.SmartTask-top .ant-input-search {
  width: 120px;
  margin-right: 15px;
}
.SmartTask-content .content-left-top .left-top-item {
  padding: 10px 10px 4px 10px;
  margin-bottom: 10px;
  background-color: #f8f9fb;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #f8f9fb;
}
.SmartTask-content .content-left-top .left-top-item:nth-last-child(1) {
  margin-bottom: 0;
}
.SmartTask-content .content-left-top {
  height: calc(100% - 36px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 15px 6px 15px;
  margin-bottom: 4px;
}
.SmartTask-content .content-left-top::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.SmartTask-content .content-left-top .active {
  border: 1px solid #39aefb !important;
  background-color: #fff;
}
.SmartTask-content .spanAct {
  color: rgb(253, 90, 90) !important;
  border: 1px solid rgb(241, 163, 163) !important;
  background-color: #eed0d0 !important;
}
.SmartTask-content .moren {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  color: #1890ff;
  font-size: 12px;
  padding: 0 3px;
  display: block;
  border-radius: 3px;
}
/* .SmartTask-content
  .ant-descriptions:hover
  > .ant-descriptions-view
  .ant-descriptions-row
  > td
  > span {
  color: #39aefb;
} */
/* .SmartTask-content
  .ant-descriptions:hover
  > .ant-descriptions-header
  .ant-descriptions-title {
  color: #39aefb;
} */
/* .SmartTask-content .content-left-top .left-top-item .delete {
  color: #fff !important;
  background-color: #f86868;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  z-index: 999;
} */
.modalAdd .item {
  display: flex;
  justify-content: space-between;
}
.modalAdd .Form {
  margin: 10px 10px 0px 0px;
  display: flex;
  flex-wrap: wrap;
}
.modalAdd .Form .ant-form-item {
  width: 48%;
  display: flex;
}
.modalAdd .Form .ant-form-item:nth-child(2n + 1) {
  margin-right: 20px;
}
.modalAdd .ant-radio-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
}
.modalAdd .ant-radio-group .ant-btn {
  width: 45%;
}

.radioGroup-content {
  display: flex;
  flex-direction: column;
  width: 180px;
}
.add-icon {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 8px;
}
.add-icon:hover,
.anticon-delete:hover {
  color: #1890ff;
}
.add-time {
  position: relative;
}
.menu-dropdown {
  width: 108px;
}
</style>

