<template>
  <div class="TaskContent">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="任务状态">
        <div class="task-one">
          <!-- <div class="one-tongji">
            <div class="xieyangshi">
              <div class="top-item">
                <span>总呼叫数</span>
                <div>2985</div>
              </div>
              <div class="top-item">
                <span>电话接通率</span>
                <div>35.41%</div>
              </div>
              <div class="top-item">
                <span>总接通数</span>
                <div>1057</div>
              </div>
              <div class="top-item">
                <span>接通意向率</span>
                <div>4.35%</div>
              </div>
              <div class="top-item">
                <span>A/B类意向客户</span>
                <div>2985</div>
              </div>
            </div>
            <div class="xieyangshi next">
              <div class="top-item">
                <span>挂机率</span>
                <div>86.47%</div>
                <span>总挂机数：914</span>
              </div>
              <div class="top-item">
                <span>短信发送成功数</span>
                <div>0</div>
                <span>短信发送总数</span>
              </div>
              <div class="top-item">
                <span>平均通话时长</span>
                <div>19s</div>
                <span>总通话时长:5小时43分</span>
              </div>
              <div class="top-item">
                <span>AI转人工成功率</span>
                <div>0%</div>
                <span>AI请求切换人工数</span>
              </div>
            </div>
          </div> -->
          <a-descriptions bordered :column="{ xxl: 4, xl: 4 }">
            <a-descriptions-item label="总呼叫数">{{
              todayDate.all_total
            }}</a-descriptions-item>
            <a-descriptions-item label="总接通数">{{
              todayDate.connect_total
            }}</a-descriptions-item>
            <a-descriptions-item label="电话接通率"
              >{{
                todayDate.all_total == 0
                  ? 0
                  : Math.ceil(
                      (todayDate.connect_total / todayDate.all_total) * 100
                    )
              }}%</a-descriptions-item
            >
            <a-descriptions-item label="A/B类意向客户">{{
              todayDate.A_B_intention_total
            }}</a-descriptions-item>
            <a-descriptions-item label="接通意向率"
              >{{
                todayDate.connect_total == 0
                  ? 0
                  : Math.ceil(
                      (todayDate.A_B_intention_total /
                        todayDate.connect_total) *
                        100
                    )
              }}%</a-descriptions-item
            >

            <a-descriptions-item label="通话总时长"
              >{{
                Math.ceil(Number(todayDate.bill_avg) / 60)
              }}分钟</a-descriptions-item
            >
            <a-descriptions-item label="平均通话时长"
              >{{
                todayDate.connect_total == 0
                  ? 0
                  : Math.ceil(todayDate.bill_avg / todayDate.connect_total)
              }}秒</a-descriptions-item
            >
            <!-- <a-descriptions-item label="短信发送成功数">{{
              todayDate.sms_total
            }}</a-descriptions-item> -->

            <!-- <a-descriptions-item label="AI转人工成功率">{{
              todayDate.tran_person
            }}</a-descriptions-item> -->
          </a-descriptions>
          <!-- {{ state }}11111 -->
          <div class="two-tongji">
            <echarts :option="option" id="myChart"></echarts>
            <!-- <div class="echarts">
              <echarts :option="option" id="myChart"></echarts>
            </div>
            <div class="echarts">
              <echarts :option="option2" id="myChart2"></echarts>
            </div> -->
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="已完成">
        <div class="task-two-top">
          <a-form class="top-form">
            <div>
              <a-input
                placeholder="电话号码"
                style="width: 120px; margin-right: 20px"
                @blur="handleChange($event, 1)"
              ></a-input>
            </div>
            <div>
              <!-- <a-select
                placeholder="通话状态"
                style="width: 120px; margin-right: 20px"
                @change="handleChange($event, 2)"
              >
                <a-select-option value="NORMAL_CLEARING"
                  >呼叫成功</a-select-option
                >
                <a-select-option value="NORMAL_TEMPORARY_FAILURE"
                  >呼叫失败</a-select-option
                >
                <a-select-option value="NO_USER_RESPONSE"
                  >线路故障</a-select-option
                >
                <a-select-option value="RECOVERY_ON_TIMER_EXPIRE"
                  >网关超时</a-select-option
                >
              </a-select> -->
              <a-cascader
                :options="options"
                placeholder="通话状态"
                @change="handleChange($event, 2)"
                style="width: 120px; margin-right: 20px"
                :display-render="displayRender"
                expand-trigger="hover"
              />
            </div>
            <a-form-item label="意向标签">
              <a-select
                placeholder="请选择"
                style="width: 120px"
                mode="multiple"
                @change="handleChange($event, 3)"
              >
                <a-select-option value="A">A</a-select-option>
                <a-select-option value="B">B</a-select-option>
                <a-select-option value="C">C</a-select-option>
                <a-select-option value="D">D</a-select-option>
                <a-select-option value="E">E</a-select-option>
                <a-select-option value="F">F</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="通话时长">
              <a-input
                @blur="handleChange($event, 4)"
                suffix="秒"
                style="width: 60px"
              ></a-input
              >~
              <a-input
                @blur="handleChange($event, 5)"
                suffix="秒"
                style="width: 60px"
              ></a-input>
            </a-form-item>
            <a-form-item label="呼叫时间">
              <a-range-picker
                @change="handleChange($event, 6)"
                :locale="locale"
                style="width: 240px"
                valueFormat="YYYY:MM:DD"
              />
            </a-form-item>
          </a-form>
          <!-- <a-button type="primary" :disabled="selectedRowKeys.length !== 0"
            >重呼</a-button
          > -->
          <a-table
            :columns="columns"
            :data-source="finishList"
            :pagination="{
              current: current,
              total: total,
            }"
            :scroll="{ x: 1200 }"
            rowKey="id"
            @change="pageChange"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          >
            <template #operation="{ record }">
              <a-button
                size="small"
                style="margin-right: 4px"
                type="primary"
                @click="callLog(record.id)"
                >通话详情</a-button
              >
              <a-button size="small" @click="handleEdit(record)">编辑</a-button>
            </template>

            <template #result_type="{ record }">
              {{ handleType(record.result_type) }}
            </template>
            <template #hangupcause="{ record }">
              {{ hangupcauseFun(record.hangupcause) }}
            </template>
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="进行中">
        <div class="task-three">
          <div class="task-three-top">
            <a-input
              placeholder="电话号码"
              style="width: 200px; margin-right: 20px"
            ></a-input>
            <a-input
              placeholder="姓名"
              style="width: 200px; margin-right: 20px"
            ></a-input>
            <a-button type="primary">
              <template #icon><SearchOutlined /></template>
              搜索
            </a-button>
          </div>
          <a-table
            :columns="columns2"
            :data-source="finishList2"
            :pagination="{
              current: current2,
              total: total2,
            }"
            @change="pageChange2"
            rowKey="id"
            style="margin-top: 20px"
          >
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="任务详情">
        <div style="margin-left: 15px">
          <p>
            计划编号 : <label>{{ detailObj.job_name }}</label>
          </p>
          <p>
            创建人 : <label>{{ detailObj.user_name }}</label>
          </p>
          <p>
            话术名称 : <label>{{ detailObj.speech_name }}</label>
          </p>
          <p>
            外呼线路组 : <label>{{ detailObj.line_group }}</label>
          </p>
          <p>
            并发数 : <label>{{ detailObj.limit }}</label>
          </p>
          <p>
            开始时间 : <label>{{ detailObj.start_time }}</label>
          </p>
          <p>
            创建时间 : <label>{{ detailObj.create_time }}</label>
          </p>
          <p>
            客户自动分配规则 :
            <label
              >{{ detailObj.crm === "private" ? "放入私海" : "放入公海" }},{{
                detailObj.turn === "turn"
                  ? "顺序推送"
                  : detailObj.turn === "all"
                  ? "全部推送"
                  : "随机推送"
              }}</label
            >
          </p>
        </div>
        <!-- <a-descriptions  bordered :column="{ xxl: 1, xl: 1 }">
            <a-descriptions-item label="计划编号">
              {{ detailObj.job_name }}
            </a-descriptions-item>
              <a-descriptions-item label="创建人">
              {{ detailObj.user_name }}
            </a-descriptions-item>
            
          </a-descriptions> -->
      </a-tab-pane>
      <template #tabBarExtraContent>
        <a-button
          v-if="[1, 4].includes(state)"
          type="primary"
          @click="startTask"
          style="margin-right: 20px"
          >开启任务</a-button
        >
        <a-button
          @click="handleImport"
          type="primary"
          style="margin-right: 20px"
          >导入名单</a-button
        >
        <a-dropdown>
          <template #overlay>
            <a-menu @click="handleMenuClick">
              <a-menu-item key="1"> 暂停任务 </a-menu-item>
              <a-menu-item style="color: red" key="2"> 删除任务 </a-menu-item>
              <a-menu-item key="3"> 复制任务 </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            更多
            <DownOutlined />
          </a-button>
        </a-dropdown>
        <a-button @click="handleRefresh"><RedoOutlined /></a-button>
      </template>
    </a-tabs>
    <a-modal
      v-model:visible="visibleImport"
      title="导入名单"
      centered
      :maskClosable="false"
      class="modalImport"
      okText="确定"
      cancelText="取消"
      @ok="okImport"
    >
      <a-form class="Form">
        <a-form-item label="号码导入">
          <a-radio-group v-model:value="formState.handle">
            <a-radio value="handle">手工添加</a-radio>
            <a-radio value="import">批量导入</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <div
        style="
          margin-left: 20px;
          justify-content: flex-start;
          flex-direction: column;
        "
        v-if="formState.handle !== 'handle'"
      >
        <div style="color: red">(建议单次导入号码数量不要超过二十万条)</div>
        <a-upload
          :file-list="formState.file"
          :remove="handleRemove"
          :before-upload="beforeUpload"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            选择文件
          </a-button>
        </a-upload>
        <div>
          *请上传 csv 格式的文件
          <!-- <a
            href="/static/xls/模板.xlsx"
            style="color: red"
            >模板下载</a
          > -->
          <a @click="download" style="color: red">模板下载</a>
        </div>
      </div>
      <div style="margin-left: 20px" v-else>
        <div style="color: red">多个号码用英文 ' , ' 分隔</div>
        <a-textarea
          placeholder="多个号码用英文','分隔"
          v-model:value="formState.number"
        />
      </div>
    </a-modal>
    <a-modal
      v-model:visible="visibleMore"
      :title="title"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      @ok="okMore"
      :width="400"
    >
      <p>确认{{ title }}?</p>
    </a-modal>
    <a-modal
      v-model:visible="visible"
      title="通话记录"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      @ok="okMore"
      :width="1000"
      class="model-three"
    >
      <CallLog :number="number" :records="records" @playVideo="playVideo" />
    </a-modal>
    <a-modal
      v-model:visible="visibleEdit"
      title="客户信息编辑"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      class="tsak-edit"
      :width="700"
    >
      <a-form :model="formStateEdit">
        <a-form-item label="电话号码">
          <a-input
            placeholder="请输入"
            disabled
            v-model:value="formStateEdit.phone"
          />
        </a-form-item>
        <a-form-item label="公司名称">
          <a-input placeholder="请输入" v-model:value="formStateEdit.company" />
        </a-form-item>
        <a-form-item label="性别">
          <a-select placeholder="请输入" v-model:value="formStateEdit.sex">
            <a-select-option value="0">女</a-select-option>
            <a-select-option value="1">男</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系人">
          <a-input placeholder="请输入" v-model:value="formStateEdit.name" />
        </a-form-item>
        <a-form-item label="详细地址">
          <a-input placeholder="请输入" v-model:value="formStateEdit.address" />
        </a-form-item>
        <a-form-item label="客户标签">
          <a-select placeholder="请输入" v-model:value="formStateEdit.label">
            <a-select-option value="A">A</a-select-option>
            <a-select-option value="B">B</a-select-option>
            <a-select-option value="C">C</a-select-option>
            <a-select-option value="D">D</a-select-option>
            <a-select-option value="E">E</a-select-option>
            <a-select-option value="F">F</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visible2"
      title="导出字段"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      @ok="okdaochu"
    >
      <a-checkbox-group v-model:value="checkboxArr">
        <a-checkbox
          v-for="item in checkboxList"
          :value="item"
          :key="item"
          :disabled="item === '电话号码'"
          >{{ item }}</a-checkbox
        >
      </a-checkbox-group>
    </a-modal>
  </div>
</template>

<script>
import echarts from "../../components/Echarts2.vue";
import { onMounted, reactive, toRefs, watch } from "vue";
import {
  DownOutlined,
  RedoOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import {
  postImport,
  postTaskCopy,
  postTaskDel,
  postTaskStart,
  getTaskDetail,
  getFinishList,
  getUnList,
  postTaskStop,
  getRecord,
  getList,
  getStatuList,
  // getRuleList,
} from "../../service/TaskContent";
import { message } from "ant-design-vue";
import { hangupcauseFun } from "../../utils/function";
// import CsvExportor from "csv-exportor";
import EXCEL from "js-export-xlsx";
import CallLog from "../../components/CallLog.vue";
import { handleType } from "../../utils/function";
const columns = [
  {
    title: "电话号码",
    dataIndex: "number",
    width: "120px",
  },
  {
    title: "客户类型",
    dataIndex: "result_type",
    width: "100px",
    slots: {
      customRender: "result_type",
    },
  },
  {
    title: "呼叫状态",
    dataIndex: "hangupcause",
    width: "120px",
    slots: {
      customRender: "hangupcause",
    },
  },
  {
    title: "呼叫时间",
    dataIndex: "calldate",
  },
  {
    title: "接听时间",
    dataIndex: "answerdate",
  },
  {
    title: "通话时长",
    dataIndex: "bill",
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
    fixed: "right",
  },
];
const columns2 = [
  {
    title: "联系方式",
    dataIndex: "number",
  },
  {
    title: "公司名称",
    dataIndex: "company",
  },
  {
    title: "公司地址",
    dataIndex: "address",
  },
  {
    title: "联系人",
    dataIndex: "person",
  },
];
const options = [
  {
    value: "NORMAL_CLEARING",
    label: "呼叫成功",
  },
  {
    value: "NORMAL_TEMPORARY_FAILURE",
    label: "呼叫失败",
    // children: [
    //   {
    //     value: "nanjing",
    //     label: "Nanjing",
    //   },
    //   {
    //     value: "nanjing2",
    //     label: "Nanjing2",
    //   },
    //   {
    //     value: "nanjing3",
    //     label: "Nanjing3",
    //   },
    //   {
    //     value: "nanjing4",
    //     label: "Nanjing4",
    //   },
    // ],
  },
  // {
  //   value: "NO_USER_RESPONSE",
  //   label: "线路故障",
  // },
  // {
  //   value: "RECOVERY_ON_TIMER_EXPIRE",
  //   label: "网关超时",
  // },
];
export default {
  name: "TaskContent",
  components: {
    echarts,
    SearchOutlined,
    DownOutlined,
    RedoOutlined,
    UploadOutlined,
    CallLog,
  },
  props: ["uuid", "state"],
  setup(props, { emit }) {
    watch(
      () => props.uuid,
      (newval) => {
        // console.log(newval);
        if (!props.uuid) {
          state.detailObj = {};
          state.finishList = [];
          state.current = 1;
          state.total = undefined;
          state.finishList2 = [];
          state.current2 = 1;
          state.total2 = undefined;
          state.todayDate = {};
          return;
        }
        state.current = 1;
        state.current2 = 1;
        getTaskDetailFun(newval);
        getFinishListFun(newval);
        getUnListFun(newval);
        getStatuListFun(newval);
      }
    );
    // console.log("props:", props.uuid);
    const state = reactive({
      activeKey: "2",
      // option: {
      //   tooltip: {
      //     trigger: "item",
      //   },
      //   title: {
      //     text: "Referer of a Website",
      //     left: "center",
      //   },
      //   legend: {
      //     orient: "vertical",
      //     left: "left",
      //   },
      //   series: [
      //     {
      //       name: "Access From",
      //       type: "pie",
      //       radius: ["40%", "70%"],
      //       avoidLabelOverlap: false,
      //       label: {
      //         show: false,
      //         position: "center",
      //       },
      //       emphasis: {
      //         label: {
      //           show: true,
      //           fontSize: "40",
      //           fontWeight: "bold",
      //         },
      //       },
      //       labelLine: {
      //         show: false,
      //       },
      //       data: [
      //         { value: 1048, name: "Search Engine" },
      //         { value: 735, name: "Direct" },
      //         { value: 580, name: "Email" },
      //         { value: 484, name: "Union Ads" },
      //         { value: 300, name: "Video Ads" },
      //       ],
      //     },
      //   ],
      // },
      // option2: {
      //   xAxis: {
      //     type: "category",
      //     data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      //   },
      //   yAxis: {
      //     type: "value",
      //   },
      //   series: [
      //     {
      //       data: [150, 230, 224, 218, 135, 147, 260],
      //       type: "line",
      //     },
      //   ],
      // },
      option: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
              { value: 300, name: "Video Ads" },
            ],
          },
        ],
      },
      visibleImport: false,
      visibleMore: false,
      title: undefined,
      visible: false,
      visibleEdit: false,
      detailObj: {},
      finishList: [],
      current: 1,
      total: undefined,
      finishList2: [],
      current2: 1,
      total2: undefined,
      wav: [], //通话详情
      number: {}, //通话详情
      records: [], //通话详情
      src: undefined,
      selectedRowKeys: [], //选中的项
      visible2: false, //模板
      checkboxArr: ["电话号码"],
      checkboxList: [],
      labelList: [],
      todayDate: {},
      // state: undefined,
    });
    // state.state = props.state;
    // console.log(props);
    const formState = reactive({
      handle: "handle",
      file: [],
      number: undefined,
    });
    const formStateRest = () => {
      formState.handle = "handle";
      formState.file = [];
      formState.number = undefined;
    };
    const formStateEdit = reactive({
      phone: "15112345678",
      company: "锋之云科技",
      sex: "0",
      name: "张三",
      address: "展涛科技2201A",
      label: "A",
    });
    onMounted(() => {
      if (!props.uuid) return;
      getTaskDetailFun(props.uuid);
      getFinishListFun(props.uuid);
      getUnListFun(props.uuid);
      getStatuListFun(props.uuid);
    });
    //刷新
    const handleRefresh = () => {
      // console.log(111111111);
      switch (state.activeKey) {
        case "1":
          break;
        case "2":
          getFinishListFun(props.uuid);
          break;
        case "3":
          getUnListFun(props.uuid);
          break;
        case "4":
          break;
      }
    };
    const handleMenuClick = (e) => {
      // console.log(e.key);
      state.visibleMore = true;
      switch (e.key) {
        case "1":
          // console.log("中止任务");
          state.title = "中止任务";
          break;
        case "2":
          // console.log("删除任务");
          state.title = "删除任务";
          break;
        case "3":
          // console.log("复制任务");
          state.title = "复制任务";
          break;
      }
    };
    const handleImport = () => {
      if (!props.uuid) {
        message.warning("请先创建任务");
        return;
      }
      state.visibleImport = true;
    };
    const okImport = async () => {
      // console.log("确认倒入名单");
      if (formState.handle === "import") {
        const formData = new FormData();
        for (let key in formState) {
          if (key === "file") {
            formData.append(key, formState[key][0]);
          } else if (key !== "number") {
            formData.append(key, formState[key]);
          }
        }
        formData.append("job_id", props.uuid);
        formData.append("access_token", sessionStorage.getItem("token"));
        const res = await postImport(formData);
        console.log(res);
        if (res.data.status === 100000) {
          message.success("导入成功");
          state.visibleImport = false;
          emit("taskChange", "导入成功");
          formStateRest();
        } else {
          message.error(res.data.msg);
          return
        }
      } else {
        if (!formState.number || !formState.number.trim()) {
          message.error("导入号码不能为空");
          return;
        }
        const res = await postImport({
          access_token: sessionStorage.getItem("token"),
          number: formState.number,
          job_id: props.uuid,
          handle: "handle",
        });
        // console.log(res);
        if (res.data.status === 100000) {
          message.success("导入成功");
          emit("taskChange", "导入成功");
          state.visibleImport = false;
          formStateRest();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const handleRemove = () => {
      formState.file = [];
    };

    const beforeUpload = (file) => {
      formState.file = [file];
      return false;
    };
    const okMore = () => {
      // console.log("确定");
      switch (state.title) {
        case "中止任务":
          // console.log("中止任务");
          // suspensionTask();
          stopTask();
          break;
        case "删除任务":
          // console.log("删除任务");
          deleteTask();
          break;
        case "复制任务":
          // console.log("复制任务");
          copyTask();
          break;
      }
      state.visible = false;
    };
    const copyTask = async () => {
      const res = await postTaskCopy({
        access_token: sessionStorage.getItem("token"),
        job_id: props.uuid,
      });
      // console.log(res);
      if (res.data.status === 100000) {
        message.success("复制成功");
        state.visibleMore = false;
        emit("taskChange", "复制成功");
      }
    };
    const deleteTask = async () => {
      const res = await postTaskDel({
        access_token: sessionStorage.getItem("token"),
        job_id: props.uuid,
      });
      // console.log(res);
      if (res.data.status === 100000) {
        message.success("删除成功");
        state.visibleMore = false;
        emit("taskChange", "删除成功");
      }
    };
    const stopTask = async () => {
      const res = await postTaskStop({
        access_token: sessionStorage.getItem("token"),
        job_id: props.uuid,
      });
      // console.log(res);
      if (res.data.status === 100000) {
        message.success("任务已暂停");
        state.visibleMore = false;
        emit("taskChange", "中止任务");
      }
    };
    const startTask = async () => {
      if (!props.uuid) {
        message.warning("请先创建任务");
        return;
      }
      const res = await postTaskStart({
        access_token: sessionStorage.getItem("token"),
        job_id: props.uuid,
      });
      // console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        emit("taskChange", "任务开启");
      } else {
        message.error(res.data.msg);
      }
    };
    const callLog = async (id) => {
      state.records = [];
      state.visible = true;
      const res = await getRecord({
        access_token: sessionStorage.getItem("token"),
        job_id: props.uuid,
        id,
      });
      // console.log(res);
      state.number = res.data.data.number;
      // state.records = res.data.data.records;
      res.data.data.records.forEach((item) => {
        item.play = false;
        state.records.push(item);
        if (item.child) {
          item.child.forEach((item2) => {
            item2.play = false;
            state.records.push(item2);
          });
        }
      });
      // console.log(state.records)
      // state.records.forEach((item) => (item.play = false));
      state.wav = res.data.data.wav;
    };
    const handleEdit = () => {
      state.visibleEdit = true;
    };
    const getTaskDetailFun = async (uuid) => {
      const res = await getTaskDetail({
        access_token: sessionStorage.getItem("token"),
        job_id: uuid,
      });
      // console.log(res);
      state.detailObj = res.data.data;
    };
    const finishParams = reactive({
      phone: undefined,
      result_type: undefined,
      hangupcause: undefined,
      bill_start: undefined,
      bill_end: undefined,
      calldate_start: undefined,
      calldate_end: undefined,
      limit: 10,
    });
    const getFinishListFun = async (uuid) => {
      const res = await getFinishList({
        access_token: sessionStorage.getItem("token"),
        job_id: uuid,
        page: state.current,
        ...finishParams,
      });
      console.log(res);
      state.finishList = res.data.data.list;
      state.labelList = res.data.data.label_list;
      state.total = res.data.data.total;
    };
    const pageChange = (e) => {
      // console.log(e);
      state.current = e.current;
      getFinishListFun(props.uuid);
    };
    const getStatuListFun = async () => {
      const res = await getStatuList({
        access_token: sessionStorage.getItem("token"),
        job_id: props.uuid,
      });
      // console.log(res);
      state.todayDate = res.data.data;
      const { A, B, C, D, E, F } = res.data.data;

      setTimeout(() => {
        // console.log(state.labelList);
        state.option.series[0].data[0].name = state.labelList[0].name;
        state.option.series[0].data[0].value = A;
        state.option.series[0].data[1].name = state.labelList[1].name;
        state.option.series[0].data[1].value = B;
        state.option.series[0].data[2].name = state.labelList[2].name;
        state.option.series[0].data[2].value = C;
        state.option.series[0].data[3].name = state.labelList[3].name;
        state.option.series[0].data[3].value = D;
        state.option.series[0].data[4].name = state.labelList[4].name;
        state.option.series[0].data[4].value = E;
        state.option.series[0].data[5].name = state.labelList[5].name;
        state.option.series[0].data[5].value = F;
      }, 100);
    };
    // const getRuleListFun = async () => {
    //   const res = await getRuleList({
    //     access_token: sessionStorage.getItem("token"),
    //     job_id: props.uuid,
    //   });
    //   console.log(res);
    // };

    const getUnListFun = async (uuid) => {
      const res = await getUnList({
        access_token: sessionStorage.getItem("token"),
        job_id: uuid,
        page: state.current2,
        limit: 10,
      });
      // console.log(res);
      state.finishList2 = res.data.data.list;
      state.total2 = res.data.data.total;
    };
    const pageChange2 = (e) => {
      // console.log(e);
      state.current2 = e.current;
      getUnListFun(props.uuid);
    };
    const handleChange = (e, play) => {
      // console.log(e, play);
      state.current = 1;
      switch (play) {
        case 1:
          finishParams.phone = e.target._value;
          getFinishListFun(props.uuid);
          break;
        case 2:
          finishParams.hangupcause = e[e.length - 1];
          getFinishListFun(props.uuid);
          break;
        case 3:
          finishParams.result_type = e.join(",");
          state.current = 1;
          getFinishListFun(props.uuid);
          break;
        case 4:
          finishParams.bill_start = e.target._value;
          getFinishListFun(props.uuid);
          break;
        case 5:
          finishParams.bill_end = e.target._value;
          getFinishListFun(props.uuid);
          break;
        case 6:
          if (!e[0]) {
            finishParams.calldate_start = undefined;
            finishParams.calldate_end = undefined;
            getFinishListFun(props.uuid);
          } else {
            finishParams.calldate_start = e[0] + " " + "00:00:00";
            finishParams.calldate_start =
              "'" + finishParams.calldate_start + "'";
            finishParams.calldate_end = e[1] + " " + "23:59:59";
            finishParams.calldate_end = "'" + finishParams.calldate_end + "'";
            getFinishListFun(props.uuid);
          }

          break;
      }
      // console.log(finishParams);
    };
    // const handlePlay = (index) => {
    //   state.records.forEach((item) => {
    //     item.play = false;
    //   });
    //   state.records[index].play = true;
    //   // state.src = state.records[index].record;
    //   let audio = document.querySelector("#audioplay");
    //   audio.src = state.records[index].record;
    //   audio.play();
    // };
    const displayRender = ({ labels }) => {
      return labels[labels.length - 1];
    };
    const onSelectChange = (selectedRowKeys) => {
      // console.log("selectedRowKeys changed: ", selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
    const download = () => {
      state.visible2 = true;
      if (state.checkboxList.length === 0) {
        getListFun();
      }
    };
    const okdaochu = () => {
      // console.log(state.checkboxArr);
      let header = state.checkboxArr;
      // CsvExportor.downloadCsv([{}], { header }, "模板.xlsx");
      EXCEL.outPut({
        header: header,
        data: [],
        name: "模板",
      });
      state.visible2 = false;
    };
    const getListFun = async () => {
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
      });
      // console.log(res);
      res.data.data.forEach((item) => state.checkboxList.push(item.label));
    };
    const playVideo = (index) => {
      state.records.forEach((item) => {
        item.play = false;
      });
      state.records[index].play = true;
    };
    return {
      ...toRefs(state),
      handleMenuClick,
      locale,
      columns,
      onSelectChange,
      columns2,
      okImport,
      handleImport,
      formState,
      handleRemove,
      beforeUpload,
      okMore,
      startTask,
      callLog,
      handleEdit,
      formStateEdit,
      finishParams,
      pageChange,
      pageChange2,
      hangupcauseFun,
      handleChange,
      // handlePlay,
      options,
      displayRender,
      download,
      okdaochu,
      getListFun,
      playVideo,
      handleType,
      handleRefresh,
    };
  },
};
</script>
<style>
.top-form {
  display: flex;
  flex-wrap: wrap;
}
.top-form .ant-form-item {
  margin-right: 20px;
}
.top-form .ant-form-item-control,
.top-form .ant-form-item-control-input,
.top-form .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}
.tsak-edit .ant-form .ant-form-item .ant-form-item-label {
  min-width: 73px;
}
.tsak-edit .ant-form .ant-form-item:nth-child(2n + 1) {
  margin-right: 40px;
}
.content-left .ant-card-body div {
  /* margin: 4px 0; */
  margin-bottom: 5px;
}
.TaskContent .ant-descriptions-item-content,
.TaskContent .ant-descriptions-item-label {
  /* width: 200px; */
  width: 12% !important;
}
/* .TaskContent .ant-descriptions-item-label {
  width: 100px;
} */
</style>
<style scoped>
.TaskContent {
  padding: 0px 10px;
  background-color: #fff;
  flex: 1;
  height: 100%;
  overflow: auto;
}
.TaskContent::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.TaskContent .ant-dropdown-trigger {
  margin-right: 20px;
}
.one-tongji {
  background-color: #f8f8fb;
  border-radius: 4px;
  height: 246px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.xieyangshi {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}
.xieyangshi .top-item {
  width: 20%;
  text-align: center;
}
.next .top-item {
  width: 25%;
  text-align: center;
}
.two-tongji {
  height: 470px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.two-tongji .echarts {
  border: 2px solid #f5f5f5;
  height: 100%;
  width: 49.5%;
  border-radius: 4px;
  padding-top: 20px;
  padding-left: 20px;
}

.tsak-edit .ant-form {
  display: flex;
  flex-wrap: wrap;
}
.tsak-edit .ant-form .ant-form-item {
  width: 300px;
}
</style>