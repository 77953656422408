import http from "../axios/http"
//导入名单（已做）
export let postImport = (params) => {
    return http.post("task/task_import", params)
}
//任务复制（已做）
export let postTaskCopy = (params) => {
    return http.post("task/task_copy", params)
}
//删除任务(已做)
export let postTaskDel = (params) => {
    return http.post("task/task_delete", params)
}
//删除任务(已做)
export let postTaskStart = (params) => {
    return http.post("task/task_start", params)
}
//====================
//某个任务暂停（已做）
export let postTaskStop = (params) => {
    return http.post("/task/task_stop", params)
}

//任务详情
export let getTaskDetail = (params) => {
    return http.get("/task/task_detail", { params })
}
//已完成电话号码(已做)
export let getFinishList = (params) => {
    return http.get("/number/finish_list", { params })
}
//未拨打电话号码列表（已做）
export let getUnList = (params) => {
    return http.get("/number/un_list", { params })
}
//通话号码记录详情（已做）
export let getRecord = (params) => {
    return http.get("/number/record", { params })
}
//获取导入模板字段
export let getList = (params) => {
    return http.get("/crm/custom_index", { params })
}
//任务状态数据
export let getStatuList = (params) => {
    return http.get("/task/task_statistics", { params })
}
//获取意向客户标签
export let getRuleList = (params) => {
    return http.get("/rule/rule_list", { params })
}