import http from "../axios/http"
//左侧列表
export let getLeft = (params) => {
        return http.get("task/tak_index", { params })
    }
    //筛选条件列表
export let getTop = (params) => {
        return http.get("task/task_index", { params })
    }
    //任务详情数据
export let getRight = (params) => {
        return http.get("task/task_update", { params })
    }
    //外呼记录数据和Table表格数据
export let getTable = (params) => {
        return http.get("task/number_record_index", { params })
    }
    //新增任务弹框数据
export let getAddTask = (params) => {
    return http.get("task/task_insert", { params })
}

//任务操作详情（添加详情）（已做）
export let getOptions = (params) => {
        return http.get("task/task_add", { params })
    }
    //任务操作详情（添加详情）（已做）
export let postTaskAdd = (params) => {
        return http.post("task/task_add", params)
    }
    //任务列表
export let gettTaskList = (params) => {
        return http.get("task/task_list", { params })
    }
    //任务编辑get
export let getTaskEdit = (params) => {
        return http.get("/task/task_edit", { params })
    }
    //保存任务编辑post
export let postTaskEdit = (params) => {
    return http.post("/task/task_edit", params)
}
//全部任务暂停（已做）
export let postTaskStops = (params) => {
    return http.post("/task/task_stops", params)
}