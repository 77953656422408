<template>
  <div :id="id" style="width: 100%; height: 100%"></div>
</template>

<script>
import { nextTick, onMounted, watch } from "vue";
import * as echarts from "echarts";

export default {
  name: "Echarts",
  props: ["option", "id"],
  setup(props) {
    onMounted(() => {
      init();
    });
    watch(props.option, () => {
      init();
    });
    let init = () => {
      let myChart = echarts.init(document.getElementById(`${props.id}`));
      // myChart.clear()
      myChart.setOption(props.option, true);
      window.onresize = function () {
        //自适应大小
        myChart.resize();
      };
      nextTick(() => {
        myChart.resize();
      });
    };
  },
};
</script>

